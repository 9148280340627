<template>
    <div class="litigationGuidelines-content">
        <div class="docs">
            <div id="docs" ref="resume">
                <div class="title">诉讼指引</div>
                <div v-html="htmlStr" class="docs-div"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as Server from '@/api/server.js'
    import { apiList } from '@/api/apiList'

    export default {
        name: 'LitigationGuidelines',
        data() {
            return {
                htmlStr: '',
                docsArr: [],
                downWordUrl: '',
                type: this.$route.query.type,
                h1Str: '',
            }
        },
        mounted() {
            this.getLitigationGuidelines()
            window.addEventListener('mousewheel', this.handleScroll, false)
        },
        created() {
            this.moduleId = this.$route.query.moduleId
        },
        methods: {
            //生成意见书
            async getLitigationGuidelines() {
                //请求数据
                let data = {
                    method: 'GET',
                    url: apiList.PCLitigationGuidelines + '/' + this.$route.query.moduleId,
                    config: {},
                    loading: true,
                }
                let dataSource = await Server.Axios(data)

                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }

                this.$emit('litigationGuidelinesShow', !!dataSource.data.returnData.content)

                if (dataSource.data.returnCode == 200) {
                    this.htmlStr = dataSource.data.returnData.content
                } else {
                    this.htmlStr = '<div class="noFound">' + dataSource.data.returnInfo + '</div>'
                }
            },
        },
    }
</script>

<style lang="less">
    .litigationGuidelines-content {
        width: 16rem;
        margin: 0.2rem auto;
        min-height: 4rem;
        text-align: left;
        line-height: 0.32rem;
        font-size: 0.16rem;
        overflow: hidden;
        .docs {
            float: left;
            margin-left: 0.6rem;
            width: 15.4rem;
            background: rgba(27, 104, 205, 0.5);
            padding: 0.24rem 0.3rem 0.6rem;
            height: 78vh;
            .title {
                font-size: 0.3rem;
                color: #fff;
                text-align: center;
                padding-bottom: 0.24rem;
                background: #0e55b9;
                border-bottom: 0.04rem solid #ffa525;
                font-weight: bold;
                width: 100%;
                margin-bottom: 0;
            }

            .docs-div {
                background: #fff;
                overflow: auto;
                color: #333;
                font-size: 0.18rem;
                padding: 0.3rem 0.45rem 0.4rem;
                // text-indent: 0.32rem;
                height: calc(78vh - 1.14rem);

                img {
                    display: block;
                    margin: 0 auto;
                    width: 80%;
                }

                h2 {
                    font-size: 0.2rem;
                    margin-top: 0.1rem;
                    font-weight: bold;
                }

                h3 {
                    text-align: center;
                    font-size: 0.18rem;
                    margin-top: 0.1rem;
                    font-weight: bold;
                }

                h4 {
                    text-align: right;
                    font-size: 0.16rem;
                    margin-top: 0.1rem;
                }

                div {
                    margin-bottom: 0;
                    text-align: justify;
                }

                p {
                    // text-indent: .32rem;
                    margin-bottom: 0;
                    text-align: justify;
                }
            }

            table {
                border-top: 1px solid #ebebeb;
                border-left: 1px solid #ebebeb;

                th {
                    border-right: 1px solid #ebebeb;
                    border-bottom: 1px solid #ebebeb;
                    text-indent: 0;
                    text-align: left;
                    padding: 0 0.1rem;

                    &:first-child {
                        width: 2rem;
                    }

                    &:last-child {
                        width: 2rem;
                    }
                }

                td {
                    border-right: 1px solid #ebebeb;
                    border-bottom: 1px solid #ebebeb;
                    text-indent: 0;
                    text-align: left;
                    padding: 0 0.1rem;
                }
            }
        }
    }

    .noFound {
        font-size: 0.16rem;
        margin-top: 0.2rem;
    }
</style>
