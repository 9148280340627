<template>
    <div class="law-content">
        <div class="docs">
            <div v-if="docs.length > 0">
                <div class="box" v-for="(item, index) in docs" :key="index">
                    <div class="title"
                        >{{ item.lawRegulationName }}<span>{{ item.terms }}</span></div
                    >
                    <div v-html="item.content" class="cont"></div>
                </div>
            </div>
            <div v-else style="padding-top: 2.2rem; transform: scale(1.5)">
                <a-empty description="暂无数据" />
            </div>
        </div>
    </div>
</template>

<script>
    import * as Server from '@/api/server.js'
    import { apiList } from '@/api/apiList'

    export default {
        name: 'Law',
        data() {
            return {
                docs: [],
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            async getList() {
                //请求数据
                let data = {
                    method: 'GET',
                    url:
                        apiList.PCGetLegalBasis +
                        '/' +
                        this.$route.query.moduleId +
                        '/' +
                        this.$route.query.resultId,
                    config: {},
                    loading: false,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                if (dataSource.data.returnCode == 200) {
                    if (dataSource.data.returnData.length > 0) {
                        dataSource.data.returnData.forEach((el) => {
                            el.content = el.content.split('\n').join('<br/>')
                        })
                        this.docs = dataSource.data.returnData
                    }
                }
            },
        },
    }
</script>

<style lang="less">
    .law-content {
        width: 15.4rem;
        margin: 0.2rem auto 0.2rem 0.6rem;
        text-align: left;
        line-height: 0.32rem;
        font-size: 0.16rem;
        padding: 0.3rem;
        background: rgba(27, 104, 205, 0.5);
        height: 78vh;
        overflow: hidden;
        .docs {
            float: left;
            width: 14.8rem;
            background: #fff;
            height: 72vh;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 0.06rem;
                height: 0.06rem;
                background-color: #e7e7e7;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #358cf3;
            }
            .box {
                border-bottom: 2px solid #358cf3;
                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;
                }

                .title {
                    line-height: 0.72rem;
                    padding-left: 0.3rem;
                    font-size: 0.2rem;
                    color: #333333;
                    font-weight: bold;

                    span {
                        font-size: 0.16rem;
                        line-height: 0.26rem;
                        background: #358cf3;
                        border-radius: 4px;
                        color: #fff;
                        padding: 0.02rem 0.08rem;
                        margin-left: 0.14rem;
                    }
                }

                .cont {
                    padding: 0.15rem 0.3rem;
                    background: #f5f9fe;
                    line-height: 0.3rem;
                    font-size: 0.16rem;
                    color: #4f555b;
                    word-break: break-all;
                }
            }
        }
    }
</style>
