<template>
    <div class="generateOpinions-content">
        <div class="docs">
            <!--startprint-->

            <div id="docs" ref="resume">
                <div v-html="h1Str"></div>

                <div v-html="htmlStr" class="docs-div"></div>
            </div>

            <!--endprint-->
        </div>

        <div ref="list" :class="list ? 'fixeda' : 'fixedb'" :style="{ right: right }">
            <div class="list1" v-if="docsArr.length != 0">
                <div class="tit" v-if="type == 1">
                    <img src="@/assets/img/result-icon1.png" alt="" />
                    意见书导航
                </div>

                <div class="tit" v-else>
                    <img src="@/assets/img/result-icon1.png" alt="" />
                    文书导航
                </div>

                <ul>
                    <li
                        v-for="(item, index) in docsArr"
                        :key="index"
                        @click="linkToTop($event, index)"
                        v-html="item"
                    ></li>
                </ul>
            </div>

            <div class="list">
                <div class="tit" v-if="type == 1">
                    <img src="@/assets/img/result-icon2.png" alt="" />
                    意见书功能
                </div>

                <div class="tit" v-else>
                    <img src="@/assets/img/result-icon2.png" alt="" />
                    文书功能
                </div>

                <ul>
                    <li @click="print" v-if="type == 1">
                        <img src="@/assets/img/result-icon3.png" alt="" />

                        <span>打印意见书</span>
                    </li>

                    <li @click="print" v-else>
                        <img src="@/assets/img/result-icon3.png" alt="" />

                        <span>打印文书</span>
                    </li>

                    <li>
                        <div @click="again(moduleId)">
                            <img src="@/assets/img/result-icon4.png" alt="" />

                            <span v-if="type == 1">重新咨询</span>

                            <span v-else>重新生成</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import * as Server from '@/api/server.js'
    import { BASE_URL, CONFIG_URL } from '@/api/server'
    import { apiList } from '@/api/apiList'

    export const cssJs = `
  @page {
    size: auto ;  /* auto is the initial value */
    margin:20mm ; /* this affects the margin in the printer settings */
  }

  body {
                            font-family:'FangSong';
                            font-size: 14pt;
                            line-height:30pt;
                            color:#000;

                         }
                         .resume_preview_page {
                            display:block;
                            font-family:'FangSong';
                         }
                         img {
                            display:block;
                            margin:0 auto ;
                        }
                        h1 {
                            font-size: 18pt;
                            text-align: center;
                            font-weight:bold;
                            font-family:'FangSong';
                            line-height:42pt;
                        }
                        h2 {
                            font-size: 16pt;
                            font-weight:bold;
                             font-family:'FangSong';
                        }
                        h3 {
                             font-size: 16pt;
                             font-weight:bold;
                             text-align: center;
                             font-family:'FangSong';
                        }
                        h4 {
                           text-align: right;
                            font-size: 14pt;
                            font-weight:400;
                             font-family:'FangSong';
                        }
                        div {
                            text-indent: 28pt;
                            margin-bottom: 0;
                            font-size: 14pt;
                            line-height:28pt;
                             font-family:'FangSong';
                        }
                         p {
                             text-indent: 28pt;
                            margin-bottom: 0;
                            font-size: 14pt;
                            line-height:28pt;
                             font-family:'FangSong';
                        }
                         table {
                            border-collapse: collapse;
                            width:100%;
                             font-family:'FangSong';

                            th {
                                text-indent: 0;
                                text-align: left;
                                padding: 0 10px;

                                &:first-child {
                                    width: 200px;
                                }

                                &:last-child {
                                    width: 200px;
                                }
                            }

                            td {
                                text-indent: 0;
                                text-align: left;
                                padding: 0 10px;
                            }
                        }`

    export default {
        name: 'GenerateOpinions',
        data() {
            return {
                htmlStr: '',
                docsArr: [],
                list: false,
                right: 0,
                height: 0,
                downWordUrl: '',
                type: this.$route.query.type,
                h1Str: '',
                // agreementsId: null,
                // indictmentId: null,
            }
        },
        mounted() {
            this.generateOpinions()
            window.addEventListener('mousewheel', this.handleScroll, false)
            this.$nextTick(() => {
                if (this.$refs.list) {
                    this.listHeight = this.$refs.list.offsetTop
                }
            })
        },
        created() {
            this.moduleId = this.$route.query.moduleId
        },
        methods: {
            again(moduleId) {
                this.$router.push({
                    name: 'Question',
                    query: {
                        moduleId: moduleId,
                        type: this.$route.query.type,
                    },
                })
            },
            goSmartInstruments(moduleId) {
                this.$router.push({
                    name: 'SmartInstrumentsQuestions',
                    query: {
                        moduleId: moduleId,
                    },
                })
            },
            //生成意见书
            async generateOpinions() {
                //请求数据
                let data = {
                    method: 'GET',
                    url:
                        apiList.PCGenerateOpinions +
                        '/' +
                        this.$route.query.resultId +
                        '/' +
                        this.$route.query.moduleId,
                    config: {},
                    loading: false,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                if (dataSource.data.returnCode == 200) {
                    this.htmlStr = dataSource.data.returnData
                    this.h1Str = '<h1>' + this.getNum(this.htmlStr, '<h1>', '</h1>') + '</h1>'
                    // this.agreementsId = dataSource.data.returnData.agreementsId;
                    // this.indictmentId = dataSource.data.returnData.indictmentId;
                    // eslint-disable-next-line no-useless-escape
                    let reg1 = /(\d{4})\-(\d{2})\-(\d{2})/g
                    this.htmlStr = this.htmlStr.replace(this.h1Str, '')
                    this.htmlStr = this.htmlStr.replace(reg1, '$1年$2月$3日')
                    this.htmlStr = this.htmlStr.replace(/<\/br>/g, '</div><div>')
                    this.htmlStr = this.htmlStr.replace(/<br>/g, '</div><div>')
                    this.htmlStr = this.htmlStr.replace(/<br\/>/g, '</div><div>')
                    this.htmlStr = this.htmlStr.replace(/<p>/g, '')
                    this.htmlStr = this.htmlStr.replace(/<\/p>/g, '')
                    this.htmlStr = this.htmlStr.replace(
                        /<td><\/td>/g,
                        "<td style='color: transparent'>&nbsp;</td>"
                    )
                    this.htmlStr = this.htmlStr.replace(
                        /<table>/g,
                        "<table border='1' cellpadding='0' cellspacing='0'>"
                    )

                    let reg = /<h2>/g
                    let i = 0
                    while (reg.test(this.htmlStr)) {
                        this.docsArr.push(this.getNum(this.htmlStr, '<h2>', '</h2>'))
                        this.htmlStr = this.htmlStr.replace('<h2>', "<h2 id='title" + i + "'>")
                        i++
                    }

                    let regs = /<table/g
                    if (regs.test(this.htmlStr)) {
                        let tableStr = this.getNum(this.htmlStr, '<table', '</table>')
                        let newTableStr = tableStr.replace(/<div>/g, '<p>')
                        newTableStr = newTableStr.replace(/<\/div>/g, '</p>')
                        this.htmlStr = this.htmlStr.replace(tableStr, newTableStr)
                    }
                } else {
                    this.htmlStr = '<div class="noFound">' + dataSource.data.returnInfo + '</div>'
                }
            },
            getNum(str, firstStr, secondStr) {
                if (str == '' || str == null || str == undefined) {
                    // "",null,undefined
                    return ''
                }
                if (str.indexOf(firstStr) < 0) {
                    return ''
                }
                let subFirstStr = str.substring(str.indexOf(firstStr) + firstStr.length, str.length)
                let subSecondStr = subFirstStr.substring(0, subFirstStr.indexOf(secondStr))
                return subSecondStr
            },
            handleScroll() {
                setTimeout(() => {
                    let scrollTop =
                        window.pageYOffset ||
                        document.documentElement.scrollTop ||
                        document.body.scrollTop
                    let width = document.body.offsetWidth
                    if (scrollTop > this.listHeight) {
                        this.right = (width - 1200) / 2 + 'px'
                        this.list = true
                    }
                    if (scrollTop <= this.listHeight) {
                        this.list = false
                    }
                }, 150)

                // console.log(scrollTop)
            },
            linkToTop(e, index) {
                let element = document.getElementById('title' + index)
                element.scrollIntoView()
                let scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop
                let width = document.body.offsetWidth
                if (scrollTop > this.listHeight) {
                    this.right = (width - 1200) / 2 + 'px'
                    this.list = true
                }
                if (scrollTop <= this.listHeight) {
                    this.list = false
                }
            },
            //导出pdf
            exportPdf() {
                this.ExportSavePdf('法律咨询意见书')
            },
            //打印
            print() {
                let iframe = document.getElementById('print-iframe')
                if (!iframe) {
                    let el = document.getElementById('docs')
                    iframe = document.createElement('IFRAME')
                    let doc = null
                    iframe.setAttribute('id', 'print-iframe')
                    iframe.setAttribute(
                        'style',
                        'position:fixed;width:800px;height:0px;left:0px;top:0px;right:0;bottom:0;'
                    )
                    document.body.appendChild(iframe)
                    doc = iframe.contentWindow.document
                    //这里可以自定义样式
                    let style = doc.createElement('style')
                    style.setAttribute('media', 'print')
                    style.innerHTML = cssJs
                    doc.write('<div>' + el.innerHTML + '</div>')
                    doc.body.appendChild(style)
                    doc.close()

                    iframe.contentWindow.focus()
                }
                if (document.querySelectorAll('#docs img').length > 0) {
                    var img = new Image()
                    img.src = document.querySelectorAll('#docs img')[0].src
                    img.onload = function () {
                        iframe.contentWindow.print()
                        document.body.removeChild(iframe)
                    }
                } else {
                    iframe.contentWindow.print()
                    document.body.removeChild(iframe)
                }
            },
            //下载word
            async downLoadWord() {
                let name
                if (document.querySelectorAll('#docs h1')[0]) {
                    name = document.querySelectorAll('#docs h1')[0].innerHTML
                } else {
                    name = '文书'
                }
                let html = this.getHtmlContent()
                // 构造blob文件流
                let html_ = new Blob([html], { type: 'text/html;charset=utf-8' })
                let formdata = new FormData()
                formdata.append('multipartFile', html_, `test.html`) //sdf.html是设置文件名
                let xhr = new XMLHttpRequest()
                xhr.open('POST', this.downWordUrl, true)
                xhr.responseType = 'blob'
                xhr.onload = function () {
                    if (this.status == 200) {
                        let blob = new Blob([this.response], { type: this.response.type })
                        let downloadUrl = URL.createObjectURL(blob)
                        let a = document.createElement('a')
                        a.href = downloadUrl
                        a.download = name + '.doc'
                        document.body.appendChild(a)
                        a.click()
                    }
                }
                xhr.send(formdata)
            },
            //下载word模板htm了
            getHtmlContent() {
                //获取html另外一种方式：this.$el.outerHTML
                const template = document.getElementById('docs').innerHTML
                let html = `<!DOCTYPE html>
                <html xmlns:v='urn:schemas-microsoft-com:vml'xmlns:o='urn:schemas-microsoft-com:office:office'xmlns:w='urn:schemas-microsoft-com:office:word'xmlns:m='http://schemas.microsoft.com/office/2004/12/omml'xmlns='http://www.w3.org/TR/REC-html40'  xmlns='http://www.w3.org/1999/xhtml' >
                <head>
                    <meta charset="utf-8">
                    <meta name="viewport" content="width=device-width,initial-scale=1.0">
                    <title>X-Find迅聘选才</title>
                    <link rel="stylesheet" href="https://cdn.bootcss.com/iview/2.14.0/styles/iview.css" />
                    <!--[if gte mso 9]>
                    <xml>
                    <w:WordDocument><w:View>Print</w:View><w:TrackMoves>false</w:TrackMoves><w:TrackFormatting/><w:ValidateAgainstSchemas/><w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid><w:IgnoreMixedContent>false</w:IgnoreMixedContent><w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText><w:DoNotPromoteQF/><w:LidThemeOther>EN-US</w:LidThemeOther><w:LidThemeAsian>ZH-CN</w:LidThemeAsian><w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript><w:Compatibility><w:BreakWrappedTables/><w:SnapToGridInCell/><w:WrapTextWithPunct/><w:UseAsianBreakRules/><w:DontGrowAutofit/><w:SplitPgBreakAndParaMark/><w:DontVertAlignCellWithSp/><w:DontBreakConstrainedForcedTables/><w:DontVertAlignInTxbx/><w:Word11KerningPairs/><w:CachedColBalance/><w:UseFELayout/></w:Compatibility><w:BrowserLevel>MicrosoftInternetExplorer4</w:BrowserLevel><m:mathPr><m:mathFont m:val='Cambria Math'/><m:brkBin m:val='before'/><m:brkBinSub m:val='--'/><m:smallFrac m:val='off'/><m:dispDef/><m:lMargin m:val='0'/> <m:rMargin m:val='0'/><m:defJc m:val='centerGroup'/><m:wrapIndent m:val='1440'/><m:intLim m:val='subSup'/><m:naryLim m:val='undOvr'/></m:mathPr></w:WordDocument>
                    </xml>
                    <![endif]-->
                </head>
                <body>
                    <span class="resume_preview_page" style="margin:0 auto;width:1200px">
                    ${template}
                    </span>
                </body>
                </html>`
                return html
            },
            changeImgToDataurl() {
                var charImg = document.getElementById('docs').getElementsByTagName('img')
                if (charImg.length == 0) {
                    this.downWordUrl = BASE_URL + CONFIG_URL + 'o/front/download/downWord'
                    this.downLoadWord()
                } else {
                    this.downWordUrl = BASE_URL + CONFIG_URL + 'o/front/download/downWord'
                }
                for (var i = 0; i < charImg.length; i++) {
                    charImg[i].setAttribute('width', charImg[i].width)
                    charImg[i].setAttribute('height', charImg[i].height)
                    var imgURL = charImg[i].currentSrc
                    this.getBase64(imgURL, charImg[i], i)
                }
            },
            getBase64(url, charImg, index) {
                var Img = new Image()
                Img.crossOrigin = 'Anonymous' //跨域必须使用，且后台也得设置允许跨域
                var dataURL = ''
                Img.src = url
                let that = this
                Img.onload = function () {
                    //要先确保图片完整获取到，这是个异步事件
                    var canvas = document.createElement('canvas'), //创建canvas元素
                        width = Img.width, //确保canvas的尺寸和图片一样
                        height = Img.height
                    canvas.width = width
                    canvas.height = height
                    canvas.getContext('2d').drawImage(Img, 0, 0, width, height) //将图片绘制到canvas中
                    dataURL = canvas.toDataURL('image/jpg') //转换图片为dataURL
                    that.condataurl ? that.condataurl(dataURL, charImg, index) : null //调用回调函数
                }
            },
            condataurl(dataURL, charImg, index) {
                charImg.src = dataURL
                let length = document.getElementById('docs').getElementsByTagName('img').length
                if (index == length - 1) {
                    this.downLoadWord()
                }
            },
        },
    }
</script>

<style lang="less">
    .fixedb {
        width: 2.5rem;
        position: static;
        float: right;
    }

    .generateOpinions-content {
        width: 16rem;
        margin: 0.2rem auto;
        min-height: 4rem;
        text-align: left;
        line-height: 0.32rem;
        font-size: 0.16rem;
        overflow: hidden;
        .docs {
            float: left;
            margin-left: 0.6rem;
            width: 12.68rem;
            background: rgba(27, 104, 205, 0.5);
            padding: 0.24rem 0.3rem 0.6rem;
            height: 78vh;
            h1 {
                font-size: 0.3rem;
                color: #fff;
                text-align: center;
                padding-bottom: 0.24rem;
                background: #0e55b9;
                border-bottom: 0.04rem solid #ffa525;
                font-weight: bold;
                width: 12.08rem;
                margin-bottom: 0;
            }

            .docs-div {
                background: #fff;
                overflow: auto;
                color: #333;
                font-size: 0.18rem;
                padding-bottom: 0.4rem;
                padding-top: 0.3rem;
                text-indent: 0.32rem;
                height: calc(78vh - 1.14rem);
                div {
                    padding: 0 0.45rem;
                }

                img {
                    display: block;
                    margin: 0 auto;
                    width: 80%;
                }

                h2 {
                    font-size: 0.2rem;
                    margin-top: 0.1rem;
                    font-weight: bold;
                }

                h3 {
                    text-align: center;
                    font-size: 0.18rem;
                    margin-top: 0.1rem;
                    font-weight: bold;
                }

                h4 {
                    text-align: right;
                    font-size: 0.16rem;
                    margin-top: 0.1rem;
                }

                div {
                    margin-bottom: 0;
                    text-align: justify;
                }

                p {
                    text-indent: 0.32rem;
                    margin-bottom: 0;
                    text-align: justify;
                }
            }

            table {
                border-top: 1px solid #ebebeb;
                border-left: 1px solid #ebebeb;

                th {
                    border-right: 1px solid #ebebeb;
                    border-bottom: 1px solid #ebebeb;
                    text-indent: 0;
                    text-align: left;
                    padding: 0 0.1rem;

                    &:first-child {
                        width: 2rem;
                    }

                    &:last-child {
                        width: 2rem;
                    }

                    &:nth-child(2) {
                        width: auto !important;
                    }
                }

                td {
                    border-right: 1px solid #ebebeb;
                    border-bottom: 1px solid #ebebeb;
                    text-indent: 0;
                    text-align: left;
                    padding: 0 0.1rem;
                }

                p {
                    text-indent: 0 !important;
                }
            }
        }

        .list {
            width: 2.5rem;
            overflow: auto;
            float: right;
            background: rgba(27, 104, 205, 0.5);
            margin-bottom: 0.2rem;

            .tit {
                font-size: 0.24rem;
                line-height: 0.62rem;
                background: #1cd4bd;
                color: #fff;
                text-align: left;
                padding-left: 0.27rem;

                img {
                    height: 0.24rem;
                    margin-right: 0.12rem;
                    margin-top: -0.04rem;
                }
            }

            ul {
                margin: 0;
                padding: 0.11rem 0.27rem;
                max-height: 4.8rem;
                overflow: auto;

                li {
                    width: 100%;
                    overflow: auto;
                    cursor: pointer;
                    border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
                    color: #fff;
                    font-size: 0.18rem;
                    line-height: 1.5;
                    padding: 0.14rem 0;

                    &:last-child {
                        border-bottom: none;
                    }

                    a {
                        color: #fff;
                    }

                    img {
                        margin-right: 0.14rem;
                        height: 0.18rem;
                        color: #015fa7;
                        margin-top: 0.06rem;
                        float: left;
                    }

                    span {
                        text-indent: 0;
                        float: left;
                    }
                }
            }
        }

        .list1 {
            width: 2.5rem;
            max-height: 5.2rem;
            float: right;
            background: rgba(27, 104, 205, 0.5);
            margin-bottom: 0.2rem;

            .tit {
                font-size: 0.24rem;
                line-height: 0.62rem;
                background: #1cd4bd;
                color: #fff;
                text-align: left;
                padding-left: 0.27rem;

                img {
                    height: 0.24rem;
                    margin-right: 12px;
                    margin-top: -5px;
                }
            }

            ul {
                margin: 0;
                padding: 0.1rem 0.27rem;
                max-height: 4.6rem;
                overflow: auto;

                li {
                    width: 100%;
                    overflow: auto;
                    cursor: pointer;
                    border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
                    color: #fff;
                    font-size: 0.18rem;
                    line-height: 1.5;
                    padding: 0.14rem 0;

                    &:last-child {
                        border-bottom: none;
                    }

                    a {
                        color: rgba(0, 0, 0, 0.65);
                    }

                    i {
                        margin-right: 0.06rem;
                        font-size: 0.18rem;
                        color: #015fa7;
                        float: left;
                    }

                    span {
                        text-indent: 0;
                        float: left;
                    }
                }

                &::-webkit-scrollbar {
                    width: 0.06rem;
                    height: 0.06rem;
                    background-color: #e7e7e7;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #358cf3;
                }
            }
        }
    }

    .noFound {
        font-size: 0.16rem;
        margin-top: 0.2rem;
    }
</style>
