<template>
    <div class="similarCase-content">
        <div class="docs">
            <div class="result-list" v-if="pageTotal != 0">
                <div
                    :class="index == docs.length - 1 ? 'box2 box' : 'box'"
                    v-for="(item, index) in docs"
                    :key="item.id"
                >
                    <div class="title">
                        <p @click="linkToDetail(item.id)">{{ item.caseTitle }}</p>
                    </div>
                    <div v-if="item.labelName" class="tagBiaoQian">
                        <div v-for="(data, index) in item.labelName.split(',')" :key="index">
                            <a-tag>{{ data }}</a-tag>
                        </div>
                    </div>
                    <div
                        class="cont result-list"
                        v-if="item.highlightFields || item.highlightFields.length != 0"
                    >
                        <a-tabs :defaultActiveKey="0" tabPosition="left">
                            <a-tab-pane
                                :tab="el.highlightField"
                                :key="i"
                                v-for="(el, i) in item.highlightFields"
                            >
                                <div v-html="el.text"></div>
                            </a-tab-pane>
                        </a-tabs>
                        <!--            <div>-->
                        <!--              <div class="left">-->
                        <!--                <div :key="i" v-for="(el,i) in item.highlightFields" @>123123</div>-->
                        <!--              </div>-->
                        <!--              <div class="right">-->
                        <!--                  <div v-html="text"></div>-->
                        <!--              </div>-->
                        <!--            </div>-->
                        <div class="case-footer">
                            <ul>
                                <li>{{ item.court }}</li>
                                <li>{{ item.caseType }}</li>
                                <li>{{ item.procedureId }}</li>
                                <li>{{ item.instrumentTypeId }}</li>
                                <li>{{ item.judgementDate.substr(0, 10) }}</li>
                                <li>{{ item.caseNo }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="pageTotal == 0" style="padding-top: 2.2rem; transform: scale(1.5)">
                <a-empty description="暂无数据" />
            </div>
        </div>

        <a-modal
            v-model="visible"
            title="案例详情"
            :footer="null"
            width="12rem"
            style="max-height: 8rem"
            :destroyOnClose="true"
        >
            <div class="bodyCenterLeft">
                <div class="titleBiao" v-html="detail.caseTitle"></div>
                <div class="titleFu" id="">
                    <span class="titileSubtitle">{{ detail.court }}</span>
                    <span class="titileSubtitle">{{ detail.caseNo }}</span>
                    <span
                        class="titileSubtitle"
                        v-if="detail.judgementDate != '' && detail.judgementDate != '1970-01-01'"
                        >{{ detail.judgementDate }}</span
                    >
                    <span class="titileSubtitle">{{ detail.reason }}</span>
                </div>
                <div
                    v-for="(item, index) in textListData"
                    class="caipanBody"
                    :key="index"
                    :id="item.type"
                >
                    <div class="caipanyaodian">
                        <div class="backlanse"></div>
                        <div class="typeText">{{ item.typeText }}</div>
                        <div class="backImg">
                            <img src="@/assets/img/juxing.png" alt="" />
                        </div>
                    </div>
                    <div
                        class="yaodianMsg"
                        v-for="(itemChild, indexChild) in item.child"
                        :id="itemChild.id"
                        :key="indexChild"
                        v-html="itemChild.text"
                    ></div>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import * as Server from '@/api/server.js'
    import { apiList } from '@/api/apiList'

    let caseType = {
        1: '刑事',
        2: '民事',
        3: '行政',
        4: '赔偿/救助',
        5: '执行/保全',
        6: '协助/制裁',
    }

    let procedureId = {
        1: '一审',
        2: '二审',
        3: '审判监督',
        4: '没收违法所得',
        5: '没收违法所得',
        6: '强制医疗',
        7: '刑罚与执行变更',
        8: '其他',
        9: '第三人撤销之诉',
        10: '特别程序',
        11: '催告案件',
        12: '督促案件',
        13: '破产/强制清算',
        14: '非诉审查',
        15: '执行实施',
        16: '执行审查',
        17: '其他执行',
        18: '财产保全',
        19: '行为保全',
        20: '行为保全复议',
        21: '证据保全',
        22: '行政赔偿',
        23: '司法赔偿',
        24: '其他赔偿',
        25: '司法救助',
        26: '其他司法救助',
        27: '认可与执行申请审查',
        28: '送达文书',
        29: '调查取证',
        30: '被判刑人移管',
        31: '罪赃移交',
        32: '引渡',
        33: '司法制裁审查',
        34: '司法制裁复议',
        35: '管辖',
    }

    //文书类型
    let instrumentTypeId = {
        1: '判决书',
        2: '裁定书',
        3: '调解书',
        4: '决定书',
        5: '通知书',
        9: '支付令',
        10: '执行令',
        6: '批复',
        7: '答复',
        8: '函',
        11: '其他',
    }
    export default {
        name: 'SimilarCase',
        data() {
            return {
                pageTotal: 0,
                docs: [],
                visible: false,
                detail: {},
                textListData: [],
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            /**
             * 根据id对应name
             */
            getNameFromConstDict: function () {
                for (let i = 0; i < this.docs.length; i++) {
                    // 案件类型caseType
                    this.docs[i].caseType = caseType[this.docs[i].caseType]
                    // 审判程序procedureId
                    this.docs[i].procedureId = procedureId[this.docs[i].procedureId]
                    // 文书类型instrumentTypeId
                    this.docs[i].instrumentTypeId = instrumentTypeId[this.docs[i].instrumentTypeId]
                }
            },
            async getList() {
                //请求数据
                let data = {
                    method: 'GET',
                    url:
                        apiList.PCGetCaseIdDetail +
                        '/' +
                        this.$route.query.moduleId +
                        '/' +
                        this.$route.query.resultId,
                    config: {},
                    loading: false,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                if (dataSource.data.returnCode == 200) {
                    this.pageTotal = dataSource.data.returnData.length
                    if (dataSource.data.returnData.length > 0) {
                        dataSource.data.returnData.forEach((el) => {
                            el.highlightFields = []
                            if (el.courtHeld) {
                                el.highlightFields.push({
                                    highlightField: '本院认为',
                                    text: el.courtHeld,
                                })
                            }
                            if (el.courtHeld) {
                                el.highlightFields.push({
                                    highlightField: '本院查明',
                                    text: el.ascertain,
                                })
                            }
                        })
                        this.docs = dataSource.data.returnData
                        this.getNameFromConstDict()
                    }
                }
            },
            async linkToDetail(id) {
                this.visible = true
                //请求数据
                let data = {
                    method: 'GET',
                    url: apiList.PCGetInstrumentDetail + '/' + id,
                    loading: false,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                if (dataSource.data.returnCode == 200) {
                    this.detail = dataSource.data.returnData
                    let newBodyText = this.detail.segments.filter(
                        (arrItem) =>
                            arrItem.typeText != null &&
                            arrItem.typeText != '案号' &&
                            arrItem.typeText != arrItem.text
                    )
                    // 循环组装数组对象
                    let dataInfo = {}
                    newBodyText.forEach((item, index) => {
                        let { typeText } = item
                        // item['id'] = 'paragraph'+ index
                        if (!dataInfo[typeText]) {
                            dataInfo[typeText] = {
                                typeText,
                                child: [],
                                type: 'type' + index,
                            }
                        }
                        dataInfo[typeText].child.push(item)
                    })
                    //对象转数组
                    let listData = Object.values(dataInfo)
                    // 组装完成 数据 放到data里
                    this.textListData = listData
                }
            },
        },
    }
</script>

<style lang="less">
    .ant-tabs-bar {
        margin-bottom: 0;
    }
    .ant-tabs-tabpane1 {
        padding: 0.16rem;
        background: rgba(2, 146, 254, 0.05);
        div {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .similarCase-content {
        width: 15.4rem;
        margin: 0.2rem auto 0.2rem 0.6rem;
        text-align: left;
        line-height: 0.32rem;
        font-size: 0.16rem;
        padding: 0.3rem;
        background: rgba(27, 104, 205, 0.5);
        height: 78vh;
        overflow: hidden;
        .docs {
            float: left;
            height: 72vh;
            overflow-y: scroll;
            width: 14.8rem;
            background-color: #fff;
            &::-webkit-scrollbar {
                width: 0.06rem;
                height: 0.06rem;
                background-color: #e7e7e7;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #358cf3;
            }
            .result-list {
                width: 100%;
                margin: 0 auto;
                padding-bottom: 0.5rem;
            }

            .list-top {
                width: 100%;
                height: 0.56rem;
                background: #fff;
                margin-bottom: 0.2rem;
                padding: 0 0.3rem;

                .text {
                    float: left;
                    text-align: left;
                    line-height: 0.56rem;
                }

                .left {
                    float: left;
                    line-height: 0.56rem;

                    span {
                        margin-right: 0.35rem;
                        cursor: pointer;

                        &.on {
                            color: #0088e8;
                        }

                        img {
                            margin-left: 0.03rem;
                        }
                    }
                }

                .right {
                    float: right;
                    line-height: 0.56rem;
                    font-size: 0.16rem;

                    a {
                        float: left;
                        color: #888 !important;
                        margin-left: 0.3rem;
                        /*cursor: pointer;*/

                        i {
                            margin-right: 0.08rem;
                            float: left;
                        }

                        &:hover {
                            color: #0292fe !important;
                        }
                    }
                }
            }

            .tagBiaoQian {
                padding: 0 0.3rem;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                div {
                    margin-bottom: 0.12rem;
                }
            }

            .box2 {
                border-bottom: none !important;
            }
            .box {
                width: 100%;
                background: #ffffff;
                padding: 0;
                border-bottom: 2px solid #358cf3;

                .title {
                    text-align: left;
                    line-height: 0.26rem;
                    font-size: 0.2rem;
                    font-weight: bold;
                    color: #333333;
                    padding: 0.3rem 0.3rem 0.15rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    p {
                        width: 6.4rem;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;

                        margin-bottom: 0;
                        cursor: pointer;
                    }

                    .right-span {
                        width: 0.8rem;
                        height: 0.2rem;
                        background: #0088e8;
                        border-radius: 10px 0px 0px 10px;
                        display: inline-block;
                        margin-right: -0.3rem;
                        float: right;
                        line-height: 0.2rem;
                        text-align: center;
                        font-size: 0.14rem;
                        color: #fff;
                        margin-top: 0.26rem;
                    }
                }

                .cont {
                    width: 100%;
                    padding: 0 0.3rem;
                    /*box-shadow: 0 6px 15px #F6F6F6;*/

                    .case-footer {
                        margin-top: 0.15rem;
                        padding-bottom: 0.2rem;
                        ul {
                            width: 100%;
                            padding: 0;
                            display: flex;
                            margin: 0 !important;
                            li {
                                color: #4f555b;
                                float: left;
                                height: 0.16rem;
                                font-size: 0.16rem;
                                line-height: 1;
                                border-right: 2px dotted rgba(136, 136, 136, 0.3);
                                padding-right: 0.1rem;
                                margin-right: 0.1rem;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                min-width: 0.6rem;
                                text-align: center;

                                &:last-child {
                                    border: none;
                                }
                            }

                            li > * {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }

                .bottom {
                    width: 100%;
                    height: 0.3rem;
                    margin-top: 0.12rem;
                    line-height: 0.3rem;
                    padding: 0 0.3rem;

                    span {
                        float: right;
                        color: #888;
                        margin-left: 0.18rem;
                        cursor: pointer;

                        &.on {
                            color: #0088e8;
                        }

                        i {
                            margin-right: 0.04rem;
                            float: left;
                        }

                        &:hover {
                            color: #0088e8;
                        }
                    }
                }
            }

            .goback {
                width: 100%;
                height: 0.55rem;
                line-height: 0.55rem;
                color: #0088e8;
                font-size: 0.16rem;
                cursor: pointer;
                text-align: center;
                border-top: 1px solid #e7e7e7;
            }
        }
    }

    .titleBiao {
        font-size: 0.24rem;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 0.15rem;
    }
    .titleFu {
        color: rgba(136, 136, 136, 1);
        font-size: 0.14rem;
    }
    .biaoqian {
        padding-left: 0.2rem;
        height: 0.42rem;
        line-height: 0.42rem;
        background: rgba(2, 146, 254, 0.05);
        /*opacity:0.05;*/
        color: #6c7277;
        margin-top: 0.15rem;
        display: flex;
        flex-direction: row;
        .biaoqianImg {
            margin-right: 0.05rem;
        }
        img {
            width: 0.16rem;
            height: 0.16rem;
            transform: rotate(90deg);
            -ms-transform: rotate(90deg); /* IE 9 */
            -moz-transform: rotate(90deg); /* Firefox */
            -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
            -o-transform: rotate(90deg);
        }
    }
    .caipanyaodian {
        display: flex;
        flex-direction: row;
        font-size: 0.2rem;
        font-weight: 400;
        color: rgba(2, 146, 254, 1);
        //background: url("./assets/juxing.png") no-repeat right;
    }
    .typeText {
        margin-right: 0.15rem;
    }
    .backImg {
        flex: 1;
        img {
            width: 100%;
            height: 0.12rem;
        }
    }
    .caipanBody {
        margin-top: 0.1rem;
    }
    .backlanse {
        margin-top: 0.07rem;
        width: 0.04rem;
        height: 0.18rem;
        background: rgba(2, 146, 254, 1);
        margin-right: 0.16rem;
    }
    .yaodianMsg {
        padding-left: 0.2rem;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        //line-height:28px;
        //margin-top: 14px;
        text-indent: 2em;
        font-size: 0.16rem;
        line-height: 0.32rem;
    }
    .ant-modal-content {
        padding-bottom: 0.2rem;
    }
    .ant-modal-body {
        max-height: 8rem;
        overflow: auto;
    }
</style>
