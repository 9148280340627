<template>
    <div class="result-main">
        <div class="er-wei-ma">
            <div class="erweimaShow" v-show="!erweimaShow"></div>
            <div class="qrcode" ref="qrCodeUrl" v-show="erweimaShow"></div>
            <div class="er-wei-ma-font">
                <span>扫描二维码</span>
                <!-- <span>生成手机端</span> -->
            </div>
        </div>
        <div class="result-tab">
            <div class="tab-left">
                <div
                    style="position: absolute; top: 0px; z-index: 999"
                    :class="key == 1 ? 'active' : ''"
                >
                    <div class="text" @click="tabChange(1)">
                        <span v-if="$route.query.type == 1">意见书</span>
                        <span v-else>文 <span style="visibility: hidden">明</span> 书</span>
                    </div>
                    <div class="ty"></div>
                </div>
                <div
                    style="position: absolute; top: 1.56rem; z-index: 99"
                    :class="key == 2 ? 'active' : ''"
                >
                    <div class="text" @click="tabChange(2)">相似案例</div>
                    <div class="ty"></div>
                </div>
                <div
                    style="position: absolute; top: 3.48rem; z-index: 9"
                    :class="key == 3 ? 'active' : ''"
                >
                    <div class="text" @click="tabChange(3)">法律依据</div>
                    <div class="ty"></div>
                </div>
                <div
                    style="position: absolute; top: 5.4rem; z-index: 8"
                    :class="key == 4 ? 'active' : ''"
                    v-show="ifLitigationGuidelinesShow"
                >
                    <div class="text" @click="tabChange(4)">诉讼指引</div>
                    <div class="ty"></div>
                </div>
            </div>
            <div class="tab-right">
                <GenerateOpinions
                    v-show="key == 1"
                    :class="key == 1 ? 'tabShow' : 'tabHide'"
                ></GenerateOpinions>
                <SimilarCase
                    v-show="key == 2"
                    :class="key == 2 ? 'tabShow' : 'tabHide'"
                ></SimilarCase>
                <Law v-show="key == 3" :class="key == 3 ? 'tabShow' : 'tabHide'"></Law>
                <LitigationGuidelines
                    v-show="key == 4"
                    :class="key == 4 ? 'tabShow' : 'tabHide'"
                    @litigationGuidelinesShow="litigationGuidelinesShow"
                ></LitigationGuidelines>
            </div>
        </div>
    </div>
</template>

<script>
    import GenerateOpinions from '@/components/PC/result/GenerateOpinions.vue'
    import SimilarCase from '@/components/PC/result/SimilarCase.vue'
    import Law from '@/components/PC/result/Law.vue'
    import LitigationGuidelines from '@/components/PC/result/LitigationGuidelines.vue'
    import QRCode from 'qrcodejs2'
    export default {
        name: 'result',
        components: {
            GenerateOpinions,
            SimilarCase,
            Law,
            LitigationGuidelines,
        },
        data() {
            return {
                key: 1,
                ifLitigationGuidelinesShow: false,
                erweimaShow: false,
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.creatQrCode()
            })
        },
        methods: {
            creatQrCode() {
                var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                    text:
                        window.location.protocol +
                        '//' +
                        window.location.host +
                        '/#' +
                        this.$route.fullPath.replace('Result', 'MobileTerminal'), // 需要转换为二维码的内容
                    width: 600,
                    height: 600,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H,
                })
                setTimeout(() => {
                    this.erweimaShow = true
                }, 300)
            },
            tabChange(key) {
                this.key = key
            },
            litigationGuidelinesShow(data) {
                this.ifLitigationGuidelinesShow = data
            },
        },
    }
</script>

<style lang="less">
    .qrcode {
        display: inline-block;
        img {
            width: 1.13rem;
            height: 1.13rem;
            background-color: #fff; //设置白色背景色
            padding: 0.06rem; // 利用padding的特性，挤出白边
            box-sizing: border-box;
        }
    }

    @media screen and (max-width: 1279px) {
        .qrcode {
            img {
                width: 1.4rem !important;
                height: 1.4rem !important;
            }
        }
    }
</style>

<style scoped lang="less">
    .erweimaShow {
        height: 1.19rem;
        width: 1.14rem;
        background: #fff;
    }

    .result-main {
        width: 16rem;
        margin: 0.2rem auto 0;
    }
    .result-tab {
        width: 16rem;
        .tab-left {
            width: 0.6rem;
            position: relative;
        }
        .tab-right {
            width: 15.4rem;
        }
        .text {
            position: relative;
            width: 0.6rem;
            padding: 0.24rem 0.18rem;
            background: #1cd4bd;
            font-size: 0.24rem;
            color: #fff;
            border-radius: 12px 0 0 12px;
            z-index: 9999;
            margin-bottom: -0.2rem;
        }
        .text:after {
            position: absolute;
            content: '';
            left: 0.06rem;
            bottom: -0.2rem;
            border-top: 0.21rem solid #1cd4bd;
            border-left: 0.54rem solid transparent;
            border-right: 0px solid transparent;
        }
        .ty {
            width: 0.7rem;
            height: 0.05rem;
            background: transparent;
            box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.4);
            transform: rotate(205deg);
            border-radius: 12px;
            margin-top: 0.38rem;
        }
        .active {
            .text {
                background: #ffa525;
            }
            .text:after {
                border-top: 0.22rem solid #ffa525;
            }
        }
    }

    @keyframes orbit {
        0% {
            opacity: 1;
            z-index: 99;
            transform: rotate(180deg);
            animation-timing-function: ease-out;
        }

        7% {
            opacity: 1;
            transform: rotate(300deg);
            animation-timing-function: linear;
            origin: 0%;
        }

        30% {
            opacity: 1;
            transform: rotate(410deg);
            animation-timing-function: ease-in-out;
            origin: 7%;
        }

        39% {
            opacity: 1;
            transform: rotate(645deg);
            animation-timing-function: linear;
            origin: 30%;
        }

        70% {
            opacity: 1;
            transform: rotate(770deg);
            animation-timing-function: ease-out;
            origin: 39%;
        }

        75% {
            opacity: 1;
            transform: rotate(900deg);
            animation-timing-function: ease-out;
            origin: 70%;
        }

        76% {
            opacity: 0;
            transform: rotate(900deg);
        }

        100% {
            opacity: 0;
            transform: rotate(900deg);
        }
    }

    .er-wei-ma {
        position: fixed;
        right: 0;
        top: 1.1rem;
        z-index: 10000000;
        .er-wei-ma-font {
            width: 1.14rem;
            height: 0.6rem;
            background: #358cf3;
            border-radius: 0px 0px 0px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: -0.08rem;
            span {
                font-size: 0.1rem;
                font-weight: 400;
                color: #ffffff;
                line-height: 0.22rem;
            }
        }
    }

    @media screen and (max-width: 1279px) {
        .er-wei-ma-font {
            width: 1.4rem !important;
        }
        .erweimaShow {
            height: 1.5rem !important;
            width: 1.4rem !important;
            background: #fff;
        }
    }
</style>
